import React, { ComponentType, useMemo } from 'react'
import {
  ActionButton,
  Bar,
  Box,
  Flex,
  Input,
  InputGroup,
  Text,
  TextArea,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import CardTitleTags from '@components/ScorecardGeneral/CardTitleTags'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import ScorecardSectionControls, {
  Direction,
} from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'
import SectionSubtitle from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionSubtitle'
import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import upperFirst from 'lodash/upperFirst'
import { SwitchOff, SwitchOn } from '@revolut/icons'
import {
  InterviewScorecardEnumSectionIds,
  InterviewScorecardSectionQuestionType,
  InterviewScorecardType,
  InterviewScorecardTemplateSectionInterface,
  InterviewScorecardSectionOptionType,
} from '@src/interfaces/interviewScorecardTemplates'
import { OptionInterface } from '@src/interfaces/selectors'
import OptionSection from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Option/OptionSection'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { LevelBasedInputs } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/LevelBasedInputs'
import produce from 'immer'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import { FormError } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'

type Props = {
  isPreview: boolean
  id: number
  titleIndexNumber: number
  editId?: number
  setEditId?: (id: number) => void
  section: InterviewScorecardTemplateSectionInterface
  onChange?: (section: InterviewScorecardTemplateSectionInterface) => void
  onDelete?: () => void
  onCopy?: () => void
  onMove?: (direction: Direction) => void
  sections?: InterviewScorecardTemplateSectionInterface[]
  errors?: FormError<InterviewScorecardTemplateSectionInterface>
  skills?: OptionInterface[]
  scorecardType?: InterviewScorecardType
  sectionOptions?: IdAndName<InterviewScorecardEnumSectionIds>[]
  sectionsFieldName?: string
  locked?: boolean
  wrapper?: ComponentType
  hideTitleIndex?: boolean
  hideControls?: boolean
}

const InterviewScorecardTemplateSection = ({
  isPreview,
  id,
  titleIndexNumber,
  editId,
  setEditId,
  section,
  errors,
  onChange,
  skills,
  scorecardType,
  onDelete,
  onCopy,
  onMove,
  sections,
  sectionOptions = [],
  sectionsFieldName,
  locked,
  wrapper,
  hideTitleIndex = false,
  hideControls = false,
}: Props) => {
  const calculateSectionTotal = (): number => {
    const questions = section.questions || []

    if (questions.length < 1) {
      return 0
    }

    if (section.section_type?.id === InterviewScorecardEnumSectionIds.Option) {
      return Math.max(...questions.map(q => (q.points ? Number(q.points) : 0)))
    }

    return (
      section.questions?.reduce(
        (prev, curr) =>
          (Number(curr.points) || 0) > 0 ? prev + (curr.points as number) : prev,
        0,
      ) || 0
    )
  }

  const total = useMemo(() => (section ? calculateSectionTotal() : 0), [section])
  const isLevels = section.section_type?.id === 'levels'

  const onChangeSectionType = (option: OptionInterface) => {
    onChange?.(
      produce(section, draft => {
        if (
          option.id === InterviewScorecardEnumSectionIds.Checkbox ||
          option.id === InterviewScorecardEnumSectionIds.Option
        ) {
          draft.questions = []
        } else {
          draft.questions = undefined
        }
        draft.placeholder_text = undefined
        draft.section_type = option as InterviewScorecardSectionOptionType

        if (scorecardType === InterviewScorecardType.Delivery) {
          draft.skills = [{ id: 'delivery', name: 'Delivery' }]
        }
      }),
    )
  }

  const onChangeQuestions = (
    questions: InterviewScorecardSectionQuestionType<number>[],
  ) => {
    onChange?.({ ...section, questions })
  }

  const sectionRouter = (type?: InterviewScorecardEnumSectionIds) => {
    switch (type) {
      case InterviewScorecardEnumSectionIds.Checkbox:
        return (
          <OptionSection
            type="checkbox"
            previewMode={isPreview}
            value={section.questions}
            onChange={onChangeQuestions}
            optionsKey={`${sectionsFieldName}.${id}.questions`}
          />
        )
      case InterviewScorecardEnumSectionIds.Option:
        return (
          <OptionSection
            type="radio"
            previewMode={isPreview}
            value={section.questions}
            onChange={onChangeQuestions}
            optionsKey={`${sectionsFieldName}.${id}.questions`}
          />
        )
      case InterviewScorecardEnumSectionIds.Dropdown:
        return isPreview ? (
          <>
            <RadioSelectInput
              label={section.placeholder_text}
              options={section.questions?.map(item => ({
                label: item.text,
                value: {
                  id: item.id!,
                  name: item.text,
                },
              }))}
              data-name={`${sectionsFieldName}.${id}.questions`}
            />
            {errors?.questions?.map((item, i) => (
              <Box key={i} data-name={`${sectionsFieldName}.${id}.questions.${i}.text`}>
                <BottomInputMessage hasError message={item?.text} />
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box mb="s-16">
              <Input
                value={section.placeholder_text}
                label="Placeholder (optional)"
                onChange={e => {
                  onChange?.({ ...section, placeholder_text: e.currentTarget.value })
                }}
              />
            </Box>

            <OptionSection
              type="radio"
              previewMode={isPreview}
              value={section.questions}
              onChange={onChangeQuestions}
              optionsKey={`${sectionsFieldName}.${id}.questions`}
            />
          </>
        )
      case InterviewScorecardEnumSectionIds.Thumbs:
        return (
          <OptionSection
            type="thumbs"
            previewMode={isPreview}
            value={section.questions}
            onChange={onChangeQuestions}
            optionsKey={`${sectionsFieldName}.${id}.questions`}
          />
        )
      case InterviewScorecardEnumSectionIds.Level:
        return (
          <LevelBasedInputs
            id={id}
            possibleSkills={skills}
            previewMode={isPreview}
            skillsDisabled={scorecardType === InterviewScorecardType.Delivery}
            onChange={onChange}
            section={section}
          />
        )
      case InterviewScorecardEnumSectionIds.Text:
        return isPreview ? (
          <TextArea
            label={section.placeholder_text}
            rows={2}
            onChange={() => {}}
            value=""
          />
        ) : (
          <Input
            data-name={`${sectionsFieldName}.${id}.placeholder_text`}
            value={section.placeholder_text}
            onChange={e => {
              onChange?.({
                ...section,
                placeholder_text: e.currentTarget.value,
              })
            }}
            label="Placeholder"
            required
          />
        )
      default:
        return null
    }
  }

  const renderControls = () => {
    if (locked || hideControls) {
      return null
    }

    return (
      <ScorecardSectionControls
        id={id}
        onDelete={onDelete}
        onCopy={onCopy}
        onMove={(_, direction) => onMove?.(direction)}
        sections={sections}
      />
    )
  }

  const Wrapper = wrapper || Widget

  return (
    <Wrapper
      style={{
        cursor: isPreview && !locked ? 'pointer' : 'default',
      }}
      key={id}
      onClick={() => {
        if (!locked && editId !== id) {
          setEditId?.(id)
        }
      }}
      data-testid={`preview_section_${titleIndexNumber}`}
    >
      <Box p="s-16">
        {isPreview ? (
          <Box mb="s-16">
            <Flex justifyContent="space-between" alignItems="start">
              {isLevels ? (
                <CardTitleTags
                  mb="s-16"
                  title="Skill assessed"
                  tags={section.skills || []}
                />
              ) : (
                <Box>
                  <Text
                    fontWeight={500}
                    fontSize="h5"
                    use="div"
                    data-name={`${sectionsFieldName}.${id}.title`}
                  >
                    {section.title || 'Section'}
                    {section.optional && <Text color="grey-tone-50"> (optional)</Text>}
                    {locked && (
                      <>
                        {' '}
                        <PredefinedLabel />
                      </>
                    )}
                  </Text>
                  <BottomInputMessage hasError message={errors?.title} />
                </Box>
              )}
              {renderControls()}
            </Flex>
            {!isLevels && section.subtitle && (
              <SectionSubtitle value={section.subtitle} />
            )}
          </Box>
        ) : (
          <>
            <VStack gap="s-16" mb="s-16">
              {!hideTitleIndex && (
                <Text fontWeight={500} fontSize="h5" use="div">
                  Section {titleIndexNumber}
                </Text>
              )}
              <Flex justifyContent="space-between" alignItems="center">
                <Bar>
                  <DropdownButton
                    options={sectionOptions}
                    onSelect={option => {
                      onChangeSectionType(option)
                    }}
                  >
                    Type: {upperFirst(section.section_type?.id || 'none')}
                  </DropdownButton>
                  {section.section_type?.id !== 'levels' && (
                    <ActionButton
                      onClick={() => {
                        onChange?.({ ...section, optional: !section.optional })
                      }}
                      useIcon={section.optional ? SwitchOn : SwitchOff}
                    >
                      Optional
                    </ActionButton>
                  )}
                </Bar>
                {renderControls()}
              </Flex>
              <InputGroup>
                <Input
                  data-name={`${sectionsFieldName}.${id}.title`}
                  aria-invalid={!!errors?.title}
                  message={errors?.title}
                  required
                  autoFocus
                  label="Title"
                  value={section.title}
                  onChange={e => {
                    onChange?.({
                      ...section,
                      title: e.currentTarget.value,
                    })
                  }}
                />
                <HTMLEditor
                  data-name={`${sectionsFieldName}.${id}.subtitle`}
                  aria-invalid={!!errors?.subtitle}
                  message={errors?.subtitle}
                  placeholder="Subtitle"
                  height={80}
                  value={section.subtitle}
                  onChange={subtitle => {
                    onChange?.({
                      ...section,
                      subtitle,
                    })
                  }}
                />
              </InputGroup>
            </VStack>
          </>
        )}
        {sectionRouter(section.section_type?.id)}
      </Box>
      {section.section_type?.id !== InterviewScorecardEnumSectionIds.Text &&
        section.section_type?.id !== InterviewScorecardEnumSectionIds.Level && (
          <Flex
            p="s-16"
            borderTopWidth={2}
            borderTopStyle="solid"
            borderColor="grey-tone-2"
            justifyContent="space-between"
          >
            <Text
              use="div"
              color="grey-tone-50"
              textAlign="right"
              fontSize="caption"
              fontWeight="bold"
              pr="6%"
            >
              Total points
            </Text>
            <Text color="grey-tone-50" use="div">
              {total ? `${total} pts` : '-'}
            </Text>
          </Flex>
        )}
    </Wrapper>
  )
}

export default InterviewScorecardTemplateSection
