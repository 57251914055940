import React from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar, DataPoint, Banner, Avatar, TextSkeleton } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  reviewCyclesNameColumn,
  reviewCyclesStatusColumn,
  reviewCyclesTimeframeColumn,
} from '@src/constants/columns/reviewCycles'
import { reviewCyclesRequests, useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { getIconImage } from '@src/pages/Hub/utils'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { useRouteMatch } from 'react-router-dom'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

const getRow = (canEdit: boolean): RowInterface<ReviewCyclesInterface> => ({
  linkToForm: ({ id }) =>
    canEdit && navigateTo(pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, { id })),
  cells: [
    {
      ...reviewCyclesNameColumn,
      width: 50,
    },
    {
      ...reviewCyclesTimeframeColumn,
      width: 40,
    },
    {
      ...reviewCyclesStatusColumn,
      width: 10,
    },
  ],
})

const ReviewCyclesTable = () => {
  const table = useTable<ReviewCyclesInterface>(
    reviewCyclesRequests,
    [],
    [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }],
  )
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const canEdit = permissions.includes(PermissionTypes.ChangeReviewCycles)
  const testCycleCookie = hasTestCycleCookie()
  const canAdd = permissions.includes(PermissionTypes.AddReviewCycles) && !testCycleCookie
  const canViewWhitelist = permissions.includes(PermissionTypes.ViewReviewsWhitelist)
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.REVIEWS_CYCLES)
  const canManagePromitions =
    !isPerformanceRoute && permissions.includes(PermissionTypes.ViewPromotionNomination)
  const canNavigateToPerformanceSettings = permissions.includes(
    PermissionTypes.ChangePerformancePreferences,
  )

  const { data: cycle } = useGetReviewCycleByOffset(0)

  const onBoardingRequired = !table.loading && table.data.length === 0

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {onBoardingRequired ? (
          <Banner>
            <Banner.Avatar>
              <Avatar variant="app" image={getIconImage('performance-reviews', 60)} />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>Performance onboarding is not finished</Banner.Title>
              <Banner.Description>
                Please, use{' '}
                <InternalLink
                  to={pathToUrl(
                    featureFlags.includes(FeatureFlags.OnboardingFlowV2)
                      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.CYCLE
                      : ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.INTRO,
                  )}
                >
                  performance onboarding flow
                </InternalLink>{' '}
                in order to setup performance settings and create initial review cycles
              </Banner.Description>
            </Banner.Content>
          </Banner>
        ) : (
          <DataPoint>
            <DataPoint.Value>
              {table?.loading ? <TextSkeleton width={50} /> : table?.count}
            </DataPoint.Value>
            <DataPoint.Label>Total</DataPoint.Label>
          </DataPoint>
        )}
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              disabled={onBoardingRequired}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL_INFO)}
              useIcon={Plus}
            >
              Add Review Cycle
            </PrimaryAction>
          )}
          {canEdit && cycle && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.PERFORMANCE.SCORECARD_PREVIEW, { id: cycle.id })}
              useIcon="DocumentsPair"
            >
              Manage scorecard
            </MoreBar.Action>
          )}
          {canNavigateToPerformanceSettings && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.SETTINGS.PERFORMANCE.REVIEW_CYCLES)}
              useIcon="Gear"
            >
              General cycle settings
            </MoreBar.Action>
          )}
          {canViewWhitelist && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.PERFORMANCE.WHITELIST)}
              use={InternalLink}
              useIcon="Questionnaire"
            >
              Whitelist
            </MoreBar.Action>
          )}
          {canManagePromitions && (
            <MoreBar.Action
              disabled={onBoardingRequired}
              use={InternalLink}
              to={pathToUrl(ROUTES.PERFORMANCE.PROMOTION_NOMINEES)}
              useIcon="ArrowUpgrade"
            >
              Manage Promotions
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<ReviewCyclesInterface>
          name={TableNames.ReviewCycles}
          hideCount
          useWindowScroll
          dataType="Review Cycle"
          row={getRow(canEdit)}
          {...table}
          emptyState={<EmptyTableRaw title="Review cycles will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default ReviewCyclesTable
